.contact-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: rgb(203, 203, 203);
  padding-top: 100px;
  padding-bottom: 100px;
}

.contact-box p{
  display: flex;
  margin: 30px;
  font-size: 25px;
  margin-bottom: 60px;
  text-align: center;
}