
.page-body{
  background-color: rgb(29, 29, 29);
  flex-direction: column;
  z-index: -2;
}

.home-area{
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.link-area{
  text-decoration: none;
}

/* ----- Header and invisible spacer ----- */

.header-img-area{
  height: 50vh
}

.banner-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  object-fit: cover;
  object-position: 50% 62%;
  z-index: -1;
  bottom: 0;
}

.main-content{
  display: flex;
  flex-direction: column;
  margin-top: 15vh;

}

/* CSS for screens smaller than 500px */
@media screen and (max-width: 500px) {
  .header-img-area{
    height: 40vh
  }
  
  .banner-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: 50% 62%;
    z-index: -1;
    bottom: 0;
  }

}

