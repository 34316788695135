.email-form {
  display: flex;
}

.email-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.email-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  /* font-size: 22px; */
  /* margin-top: -20px; */
}

.message__box{
  min-width: 25vw;
  min-height: 10vh;
}

.email__from{
  min-width: 18vw;
}

.send-button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height:40px;
  background-color: orange;
  border-radius: 15px;
  border: none;
}

.send-button:hover {
  text-decoration: underline;
}

/* 1000px style change */

@media screen and (max-width: 1000px) {
  .email-inputs {
    flex-direction: column;
    align-items: flex-start; /* Adjust alignment if needed */
  }

  .email-form {
    font-size: 18px;
  }
}