.safety-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.safety-box-text{
  display: flex;
  flex-direction: column;
  width: 75vw;
  margin-bottom: 20px;
}

.safety-box-text p{
  display: flex;
  color: rgb(255, 255, 255);
  margin:15px;
  margin-top: 50px;
}

.safety-links{
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

}

.safety-links a{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  background-color: rgb(250, 96, 12);
  padding: .5vw;
  border-radius: 5px;
  font-size: 15px;
}

.safety-links a:hover {
  text-decoration: underline;
}

.safety-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  width: 40px;
  height: 50px
  
}

/* CSS for screens larger than 1000px */
@media screen and (min-width: 1000px) {
  .safety-box-text p{
    border-right: solid;
    border-left: solid;
    padding-left: 20px;
    padding-right: 20px;
  }

}

