.navbar-map{
  display: flex;
  justify-content: space-between;
  background-color: rgb(29, 29, 29);
  width: 100%;
}

.navbar-map a{
  display: flex;
  color: white;
}

.navbar-alt-logo{
  display: flex;
  max-width: 8vw;
  min-width: 70px;
  margin: 20px;
  /* background-color: rgb(255, 255, 255); */
}

.back-button{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(250, 96, 12);
  padding: 15px;
  border-radius: 15px;
  text-decoration: none;
  margin-right: 15px;
  width: 40px;
  height: 10px;
  text-decoration: none;
}

.back-button:hover{
  text-decoration: underline;
}

.navbar-links-map {
  display: flex;
  flex-direction: row;
  
  justify-content: flex-end;
  text-decoration: none;
  list-style-type: none;
}
