html {
  scroll-behavior: smooth;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgb(29, 29, 29); */
  /* background: linear-gradient(rgba(255, 255, 255, 0.334), rgba(255, 255, 255, 0.001)); */
  width: 95vw;
  z-index: 100;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-decoration: none;
  width: 75vw;
}

.navbar-links a:hover {
  text-decoration: underline;
}

.navbar-logo{
  display: flex;
  max-width: 10vw;
  margin: 20px;
  min-width: 150px;
  /* background-color: rgb(255, 255, 255); */
}

.navbar-links li{
  display: flex;
  text-decoration: none;
}

.navbar-links a{
  display: flex;
  text-decoration: none;
  margin-left: 2vw;

  color: black;
}

/* slide nav */

.mobile-nav{
  display: flex;
  justify-content: center;
  align-items: center;
}

.bars-button{
  display: flex;
  align-items: center;
  background-color: orange;
  border: none;
  border-radius: 15px;
  padding: 8px;


  font-size: 40px;
}



/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 30px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.sidenav.open {
  width: 250px;
}

/* use this if you want to push content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}

.safety-icon {
  font-size: 10px !important;
}

/* height is less than 450px
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
} */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Adjust the transparency as needed */
  z-index: -1; /* Place the overlay behind other content */
  display: none; /* Initially hidden */
}

/* Show the overlay when the navigation is open */
.sidenav.open .overlay {
  display: block;
}


/* CSS for screens larger than 1000px */
@media screen and (min-width: 1000px) {
  .mobile-nav {
    display: none; /* Hide mySidenav */
  }
  .bars-button {
    display: none; /* Hide the button */
  }
}

/* CSS for screens smaller than 1000px */
@media screen and (max-width: 1000px) {
  .navbar-links {
    display: none; /* Hide navbar links */
  }
  .bars-button {
    display: flex; /* Show the button */
  }

}

/* CSS for screens smaller than 500px */
@media screen and (max-width: 500px) {
  .navbar-logo{
    min-width: 30vw;
  }

}


