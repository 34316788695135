



.map-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(29, 29, 29);
  color:white;

  height: 100%;
}

.preview-and-map-area{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(40, 40, 40);
  border-radius: 10px;
  margin-left: 3vw;
  margin-right: 3vw;
  /* width: 80vw; */
}

/* FILTER DROPDOWNS */

.filters{
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-batch{
  display: flex;
  flex-direction: row;

}

.filter-dropdowns{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
}

.filters option{
  margin-left:100px !important;
  padding-left:100px !important;
}


.map-size-button-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 5px;
}

.map-container select{
  display: flex;
  margin-left: 1vw;
  min-width: 8vw;
  border-radius: 15px;
}

.map-container select:hover{
  background-color: rgb(214, 213, 213);
}

.map-container button{
  display: flex;
  margin-left: 1vw;
}

.clear-filter{
  display: flex;
  margin-left: 1vw;
  background-color: rgb(255, 255, 255);
  border: none;
  margin: 5px; 
}

.clear-filter:hover{
  background-color: rgb(232, 232, 232);
}


/* -----MAP----- */

.map-area{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 10px;

}

.map{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80vw;
}

/* -----PREVIEW----- */

.map-preview a{
  display: flex;
  text-decoration: none;
}

.map-preview img{
  display: flex;
  width: 120px;
  height: 60px;
  object-fit: cover;
}

.map-preview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.733);
  color: black;
  border: 1px solid #ccc;
  padding: 4px;
  padding-bottom: 9px;
  z-index: 1000;
  top: -80px;
  left: -30px;
  width: 130px;
  height: 70px;
  font-size: 15px;
  text-decoration: none;
  border-color: black;
  border-radius: 5px;
}

.map-preview-name-img{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}



.map-preview p{
  display: flex;
  font-size: 15px;
  color: rgb(0, 0, 0);
  margin-top: 0px;
}

.map-preview button{
  display: flex;
  justify-content: center;
  align-items: center;
  width:20px;
  height: 20px;
  background-color: rgb(255, 255, 255);
  font-size: 10px;
  /* margin-left: 5px; */
  margin-left: -22px;
  border-radius: 50px;
}



/* -----LOCATION LIST----- */

.location-list{
  display: flex;
  justify-content:center;
  justify-self: center;
  height: 100%;
  width: 100%;
}

.location-list ul{
  display: flex;
  align-content: center;
  flex-direction: column;
  padding-left: 0
  
}

.location-list li{
  display: flex;
  
  text-decoration: none;
  flex-direction: row;
  
}

.location-list a{
  display: flex;
  color: white;
  text-decoration: none;
  justify-content: space-between;
  background-color: rgb(40, 40, 40);
  margin-bottom: 2vh;
  border-radius: 10px;
  width: 90vw;
}

.location-list a:hover{
  /* text-decoration: underline; */
  background-color: rgb(56, 55, 55);
}

.location-list p{
  display: flex;
  color: white;
  margin-right: 4vw;
  padding-left:1vw;
  font-size: 15px;
}



/* CSS for screens smaller than 1000px */
@media screen and (max-width: 1000px) {
  .filters{
    display: flex;
    justify-content: center;
  
  }
  
  .filter-batch{
    display: flex;
    flex-direction: column;
  }
  
  .filter-dropdowns{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
  }

  .filter-dropdowns input{
    margin:10px
  }

  .filter-dropdowns select{
    margin:3px
  }

  .text-search-container{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin: -5px
  }

  .clear-filter-container{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }

  .clear-filter{
    display: flex;
    justify-content: center;
    margin-left: 1vw;
    background-color: rgb(255, 255, 255);
    max-width: 200px;
  }

  /* Location List */

  .location-list p{
    display: flex;
    color: white;
    margin-right: 4vw;
    padding-left:1vw;
    /* font-size: 15px; */
  }

  .data-hide{
    display: none !important;
  }

}
