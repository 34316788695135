.stats-area{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  color: white;
  margin-bottom: 40px;
}

.stats-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  color: white;
  width: 80vw;
  max-width: 1200px;
  margin: 0 auto;
  background-color: rgb(47, 47, 47);
}

.stats-text{
  margin: 15px;
}

.stat-count{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 15vw;
  align-self: flex-end;
}

.stat-description{
  font-size: 18px;
  margin-bottom: -10px;
}

.number{
  display: flex;
  justify-content: center;
  background-color: orange;
  font-size: 30px;
  border-radius: 10px;
  width: 50px;
}

.stats-link{
  text-decoration: none;
}

/* screens smaller than 1000px */
@media screen and (max-width: 400px) {
  .number{
    padding: 10px;
  }

}

/* screens larger than 1000px */
@media screen and (min-width: 400px) {
  .number{
    padding: 15px;
  }

}