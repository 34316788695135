.climb-page-area{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(29, 29, 29);
  width: 100vw
  
}

.img-data-box{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.climb-title{
  display: flex;
  color: white;
  font-size: 25px;
  
}

.climb-page-area img {
  display: flex;
  justify-content: center;
  object-fit: cover;
  /* width: 100%; */
  margin: 20px;

}

.photo_credit{
  display: flex;
  color: white;
  font-size: 14px;
  justify-content: flex-start;
  margin: 0;
  width: 90vw;
}

.climb-data{
  display: flex;
  flex-direction: column;
  color:white;
  background-color: rgb(40, 40, 40);
  margin: 20px;
  padding: 10px;
  border-radius: 15px;
  width: 90vw;

}

.grade-modal{
  display: flex;
  flex-direction: row;
}

.grade-modal button{
  font-size: 12px;
}

.modal-box{
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box p{
  font-size: 15px;
}

.google-maps{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  background-color: orange;
  border: none;
  border-radius: 15px;
  padding: 6px;
  width: 300px;
  font-size: 15px;
  margin: 10px;
}

/* screens smaller than 1000px */
@media screen and (max-width: 1000px) {

  .climb-page-area img {
    display: flex;
    max-width: 80vw;
    min-width: 50vw;
  }

}

/* screens larger than 1000px */
@media screen and (min-width: 1000px) {

  .climb-page-area img {
    display: flex;
    max-width: 60vw;
    min-width: 40vw;
    max-height: 30vw;
  }

}
