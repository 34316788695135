.about-box{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100vw
}

.about-box img{
  display: flex;
  height: 45vh;
  width: 30vw;
  object-fit: cover;
  margin: 50px
}

.bottom-left {
  position: absolute;
  bottom: 6vh; /* move vertical */
  left: 7.2vw; /* move horizontal */
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  padding: 20px; /* Add padding to create space */
  background-color: rgba(0, 0, 0, 0.5); /* Add a background color for better visibility */
}

.about-box-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  height: auto;
  color: white;
  /* border-top: solid;
  border-bottom: solid; */
  margin: 50px;
}

/* smaller than 1000px style change */
@media screen and (max-width: 1000px) {
  .about-box img {
    display: none;
  }
}

/* larger than 1000px style change */
@media screen and (min-width: 1000px) {
  .about-box-text{
    border-left: solid;
    padding-left: 20px;
  }
}