.map-box{
  display: flex;
  justify-content: space-around;
  align-items: center;
 
  position: relative;
}

.map-box img{
  display: flex;
  height: 45vh;
  width: 30vw;
  object-fit: cover;
  margin: 50px
}

.bottom-left {
  position: absolute;
  bottom: 6vh; /* move vertical */
  left: 7.2vw; /* move horizontal */
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  padding: 20px; /* Add padding to create space */
  background-color: rgba(0, 0, 0, 0.5); /* Add a background color for better visibility */
}

.map-box-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  height: auto;
  color: white;
  /* border-top: solid; */
  /* border-bottom: solid; */
  margin: 50px;
}

.bottom-left:hover{
  text-decoration: underline;
}

.map-box-button a{
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  background-color: rgb(250, 96, 12);
  border: none;
  border-radius: 15px;
  padding: 10px;
  font-size: 25px;
  color: white;
  width: 100px;
  height: 20px;
}

/* smaller than 1000px style change */
@media screen and (max-width: 1001px) {
  .map-box img {
    display: none;
  }

  .bottom-left{
    display: none;
  }


}

/* screens larger than 1000px */
@media screen and (min-width: 1001px) {


  .map-box-button {
    display: none;
  }

  .map-box-text{
    border-right: solid;
    padding-right: 20px;
  }
  
}