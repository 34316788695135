/* Footer container */
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: rgb(29, 29, 29);
  color: white;
  width: 100vw;
  align-items: center;
}

/* logo area */
.logo {
  width: auto;
  height: auto;
  width: 200px;
  margin: 20px;
}

/* Resource list */

.resources{
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.resources ul {
  list-style-type: disc; /* This should work */
  padding: 0;
}


/* Remove text styles from links */
.resources ul li a {
  text-decoration: none;
  color: inherit;
}

.resources ul li a:hover{
  text-decoration: underline;
}

@media screen and (max-width: 700px) {
  .logo {
    display: none;
  }

  .footer{
    display: flex;
    justify-content: center;
  }

  .resources{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;

  }

  .resources ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    align-self: flex-start;
    align-content: center;
    
  }

}
