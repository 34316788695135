body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

h1 {
  display: flex;
  font-family: 'Montserrat', sans-serif;
}

/* h2 {
  display: flex;
  font-family: 'Montserrat', sans-serif;
} */